import moment from 'moment'

import { defaultValue } from './index'

export default (state = defaultValue, { type, payload }) => {
  console.log('dispatch', type, 'payload', payload)

  switch (type) {
    case 'reset':
      return defaultValue

    case 'load':
      const dob = payload.data.personalInformation.fields.dob

      return {
        ...payload.data,
        initialized: true,
        personalInformation: {
          ...payload.data.personalInformation,
          fields: {
            ...payload.data.personalInformation.fields,
            dob: dob ? moment(dob).toDate() : undefined,
          },
        },
      }

    case 'init':
      return {
        ...defaultValue,
        currentStep: 'personalInformation',
        initialized: true,
      }

    case 'update':
      return {
        ...state,
        [payload.step]: {
          ...state[payload.step],
          fields: payload.fields,
        },
      }

    case 'setStep': {
      console.log('payload', payload)
      const validStep = steps.find(s => s === payload.step)

      return {
        ...state,
        currentStep: validStep ? payload.step : state.currentStep,
      }
    }

    case 'previousStep': {
      const currentIndex = steps.findIndex(s => s === state.currentStep)
      const previousIndex = currentIndex - 1
      console.log('previous', previousIndex, 'current', currentIndex)
      return {
        ...state,
        currentStep:
          previousIndex >= 0 ? steps[previousIndex] : state.currentStep,
      }
    }

    case 'nextStep': {
      const currentIndex = steps.findIndex(s => s === state.currentStep)
      const nextIndex = currentIndex + 1
      console.log('nextIndex ', nextIndex, 'current', currentIndex)
      return {
        ...state,
        currentStep:
          nextIndex < steps.length ? steps[nextIndex] : state.currentStep,
      }
    }

    case 'submitStart':
      return {
        ...state,
        submitting: true,
        submitError: null,
      }

    case 'submitError':
      return {
        ...state,
        submitting: false,
        submitError: payload.error,
      }

    case 'submitSuccess':
      return {
        ...defaultValue,
        initialized: true,
      }

    default:
      throw new Error()
  }
}

const steps = [
  'personalInformation',
  'businessExperience',
  'educationalBackground',
  'generalInformation',
  'franchiseOwnership',
]
