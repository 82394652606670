module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(min-width: 320px)","sm":"(min-width: 640px)","md":"(min-width: 768px)","lg":"(min-width: 1024px)","xl":"(min-width: 1280px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["JosefinSlab","Avenir"],"urls":["/Users/nthungdev/Projects/little-scholar/src/styles/typography.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Little Scholar","short_name":"Little Scholar","description":"Little Scholar is an organization dedicated to delivering a student focused and highly contextualized American English learning program that accommodates all levels of proficiency.","start_url":"/","display":"standalone","icon":"/Users/nthungdev/Projects/little-scholar/src/static/images/logos/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6cc9d78038d75af33ec7e91d84540799"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
