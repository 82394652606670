import React, { createContext, useContext, useReducer, useEffect } from 'react'
import axios from 'axios'
import reducer from './reducer'

export const defaultValue = {
  personalInformation: {
    fields: {},
    errors: {},
  },
  businessExperience: {
    fields: {},
    errors: {},
  },
  educationalBackground: {
    fields: {
      educations: [{}],
    },
    errors: {},
  },
  generalInformation: {
    fields: {},
    errors: {},
  },
  franchiseOwnership: {
    fields: {},
    errors: {},
  },
  currentStep: 'personalInformation',
  submitting: false,
  submitError: null,
  initialized: false,
}

const PreliminaryApplicationContext = createContext({
  ...defaultValue,
  update: async (step, fields) => {},
  /**
   * @param {Object} fields
   */
  goNextStep: fields => {},
  goPreviousStep: () => {},
  /**
   * @param {Object} fields
   */
  submit: async () => {},
  init: () => {},
  reset: () => {},
  setStep: step => {},
  /**
   * @param {string} step
   * @param {Object} form
   * @returns {boolean}
   */
  validateForm: (step, form) => {},
})

export const usePreliminaryApplication = () => {
  const context = useContext(PreliminaryApplicationContext)
  if (context === undefined) {
    throw new Error(
      'Please make sure PreliminaryApplicationProvider in one of the parents'
    )
  }
  return context
}

export const PreliminaryApplicationProvider = props => {
  const [state, dispatch] = useReducer(reducer, defaultValue)

  useEffect(() => {
    // TODO: load persisted data if any

    try {
      const localForm = localStorage.getItem('applicationForm')

      if (localForm) {
        const parsed = JSON.parse(localForm)
        console.log('parsed', parsed)
        console.log('load persisted data')
        dispatch({ type: 'load', payload: { data: parsed } })
      } else {
        console.log('init')
        init()
      }
    } catch (error) {
      console.error('something went wrong', error)
      init()
    }
  }, [])

  useEffect(() => {
    console.log('persist data', state)
    localStorage.setItem('applicationForm', JSON.stringify(state))
  }, [state])

  const init = () => dispatch({ type: 'init' })

  const reset = () => dispatch({ type: 'reset' })

  const update = async (step, fields) => {
    dispatch({ type: 'update', payload: { step, fields } })
  }

  const goNextStep = fields => {
    // TODO: verify fields?
    dispatch({ type: 'update', payload: { step: state.currentStep, fields } })
    // localStorage.setItem('applicationForm', JSON.stringify(state))
    dispatch({ type: 'nextStep' })
  }

  const goPreviousStep = () => {
    dispatch({ type: 'previousStep' })
  }

  const submit = async () => {
    dispatch({ type: 'submitStart' })

    const valid = true

    console.log('state to submit:', state)

    if (valid) {
      // TODO: save form to firestore
      const url =
        process.env.FUNCTIONS_ENDPOINT +
        process.env.FUNCTIONS_SUBMIT_APPLICATION
      const {
        personalInformation,
        businessExperience,
        educationalBackground,
        generalInformation,
        franchiseOwnership,
      } = state
      const { success, data } = await axios
        .post(url, {
          form: {
            personalInformation: personalInformation.fields,
            businessExperience: businessExperience.fields,
            educationalBackground: educationalBackground.fields,
            generalInformation: generalInformation.fields,
            franchiseOwnership: franchiseOwnership.fields,
          },
        })
        .then(res => res.data)
        .catch(err => {
          if (err.response) return err.response.data
          else throw Error('An error occured')
        })

      console.log('success?', success, 'data', data)
      if (success) {
        console.log('submit application successfully')
        localStorage.removeItem('applicationForm')
        dispatch({ type: 'submitSuccess' })
      } else {
        // TODO:
        dispatch({
          type: 'submitError',
          payload: {
            error: ['Submission failed.'],
          },
        })
      }
    } else {
      // TODO:
      dispatch({
        type: 'submitError',
        payload: {
          message: 'Invalid form',
        },
      })
    }
  }

  const setStep = step => {
    console.log('set step', step)
    dispatch({ type: 'setStep', payload: { step } })
  }

  // console.log('state', state)

  return (
    <PreliminaryApplicationContext.Provider
      value={{
        ...state,
        goNextStep,
        goPreviousStep,
        submit,
        init,
        reset,
        update,
        setStep,
      }}
    >
      {props.children}
    </PreliminaryApplicationContext.Provider>
  )
}
