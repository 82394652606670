/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import firebase from 'gatsby-plugin-firebase'
import React from 'react'
import { PreliminaryApplicationProvider } from './src/contexts/preliminary-application-context'
import 'typeface-josefin-slab'
import './src/styles/tailwind.css'
import 'firebase/analytics'

firebase.analytics()

export const wrapRootElement = ({ element }) => (
  <PreliminaryApplicationProvider>{element}</PreliminaryApplicationProvider>
)
